import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Select, Input, DatePicker, Spin } from 'antd';
import { SearchOutlined, RedoOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
    cryptoOptions,
    currencyTypes,
    fiatOptions,
    transactionStatusGroup,
    transactionTypes,
} from '../../utils/constant';
import MultiSelect from '../common/MultiSelect';
import apiRequest from '../../utils/api';
import useDebounce from '../../utils/useDebounce';
import dayjs from 'dayjs';
import DrawerFilters from '../DrawerFilters';
import ThemeButton from '../common/ThemeButton';

const { RangePicker } = DatePicker;
const dateFormat = 'DD.MM.YYYY';

const TransactionFilterDrawer = props => {
    const { open, setOpen, handleSearch, filterQuery, state, clearQueryRender } = props;
    const [form] = Form.useForm();
    const [formValue, setFormValue] = useState({
        transactionType: [],
        currencyType: [],
        statusGroup: [],
        fiatCurrency: [],
        cryptoCurrency: [],
        active: [],
        sender: '',
        receiver: '',
        transactionId: '',
        dateFilter: [],
        dateUpdated: [],
    });
    const [loading, setLoading] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);

    const whiteLabelId = localStorage.getItem('whiteLabelId');
    const email = localStorage.getItem('email');
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const image_base_url = useSelector(state => state.config.image_base_url);

    const handleCloseDrawer = () => {
        setOpen(false);
    };

    useEffect(() => {
        const newFormValue = {};
        filterQuery.forEach(item => {
            if (item.type === 'transactionType') {
                newFormValue.transactionType = item.value;
            } else if (item.type === 'fiatCurrency') {
                newFormValue.fiatCurrency = item.value;
            } else if (item.type === 'cryptoCurrency') {
                newFormValue.cryptoCurrency = item.value;
            } else if (item.type === 'status') {
                newFormValue.statusGroup = item.value;
            }
            // else if (item.type === 'active') {
            //     newFormValue.active = item.value;
            // }
            // else if (item.type === 'dateFilter') {
            //     const { startDate, endDate } = state.dateFilter;
            //     if (startDate && endDate) {
            //         newFormValue.dateFilter = [dayjs(item?.startDate), dayjs(item?.endDate)];
            //     }
            // } else if (item.type === 'amountRange') {
            //     newFormValue.amountRangeFrom = item.amountRange?.from;
            //     newFormValue.amountRangeTo = item.amountRange?.to;
            // } else if (item.type === 'sender') {
            //     newFormValue.sender = item.sender;
            // } else if (item.type === 'reciever') {
            //     newFormValue.reciever = item.reciever;
            // }
        });
        setFormValue(newFormValue);
    }, [filterQuery]);

    const getClientDetails = useDebounce((type, value) => {
        onChangeFilter(type, value);
    }, 500);

    const onChangeFilter = async (type, value) => {
        let filterParams = {
            paymentProvider: paymentProvider,
            whiteLabelId: whiteLabelId,
        };

        const body = {
            email,
            filterType: type,
            filterValue: value,
            filterParams,
        };

        setLoading(true);
        try {
            const response = await apiRequest('/filter-transactions', 'POST', body);

            if (response.success) {
                setLoading(false);
                if (response.data) {
                    const formattedOptions = response.data?.clientData.map(client => ({
                        value: client,
                        label: client,
                    }));
                    setDropdownData(formattedOptions);
                }
            } else {
                return [];
            }
        } catch (error) {
            setLoading(false);
            return [];
        }
    };

    const handleResetValue = () => {
        setFormValue({
            transactionType: [],
            statusGroup: [],
            fiatCurrency: [],
            cryptoCurrency: [],
        });
        form.resetFields();
        clearQueryRender();
    };

    function TransactionFilterButtons() {
        return (
            <div className="py-[8px] sm:py-[16px] grid grid-cols-[auto_1fr] lg:mt-6 sm:flex sm:justify-end sm:items-center gap-2">
                <ThemeButton
                    icon={<RedoOutlined />}
                    action={handleResetValue}
                    text="Reset"
                    autoWidth={true}
                    className="reset-filter-btn"
                />
                <ThemeButton
                    icon={<SearchOutlined />}
                    type="primary"
                    autoWidth={true}
                    action={() => {
                        handleSearch(formValue);
                        handleCloseDrawer();
                    }}
                    text="Search"
                />
            </div>
        );
    }

    const handleSelectChange = () => {
        setDropdownData([]);
    };

    return (
        // TODO : WE can use that commented code in v2
        <DrawerFilters
            title="Filters"
            onClose={handleCloseDrawer}
            open={open}
            footer={<TransactionFilterButtons />}
            form={form}>
            <Form.Item label="Transaction Type" name="transactionType">
                <MultiSelect
                    options={transactionTypes}
                    placeholder="Select an option"
                    name="transactionType"
                    formValue={formValue}
                    onChange={selectedValues =>
                        setFormValue({ ...formValue, transactionType: selectedValues })
                    }
                />
            </Form.Item>
            {/* <Form.Item label="White Label" name="currencyType">
                        <MultiSelect
                            options={currencyTypes}
                            placeholder="Select an option"
                            name="currencyType"
                            formValue={formValue}
                            onChange={selectedValues =>
                                setFormValue({ ...formValue, currencyType: selectedValues })
                            }
                        />
                    </Form.Item> */}
            {/* <Form.Item label="Search All Transactions" name="transaction">
                    <Input
                        placeholder="Enter Transation ID"
                        prefix={<SearchOutlined />}
                        className="w-full p-2"
                    />
                </Form.Item> */}
            {/* <Form.Item label="Sender" name="sender">
                        <div className="">
                            <SearchOutlined className="custom-search-icon" />
                            <Select
                                showSearch={true}
                                // value={filters['phoneNumber']}
                                value={formValue.sender}
                                placeholder="Search sender"
                                defaultActiveFirstOption={false}
                                // suffixIcon={loading && <Spin size="small" />}
                                suffixIcon={null}
                                filterOption={false}
                                onSearch={value => {
                                    value !== '' && getClientDetails('sender', value);
                                }}
                                // onChange={value => handleFilterChange('phoneNumber', value)}
                                onChange={value => setFormValue({ ...formValue, sender: value })}
                                notFoundContent={null}
                                options={(dropdownData || []).map(data => ({
                                    value: data.value,
                                    label: data.label,
                                }))}
                                onSelect={handleSelectChange}
                                className="filter-select"
                                loading={loading}
                                dropdownRender={menu => (
                                    <div>
                                        {loading ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    padding: '8px',
                                                }}>
                                                <Spin size="small" />
                                            </div>
                                        ) : null}
                                        {menu}
                                    </div>
                                )}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item label="Receiver" name="receiver">
                        <div className="">
                            <SearchOutlined className="custom-search-icon" />
                            <Select
                                showSearch={true}
                                // value={filters['phoneNumber']}
                                value={formValue.receiver}
                                placeholder="Search Receiver"
                                defaultActiveFirstOption={false}
                                suffixIcon={null}
                                filterOption={false}
                                onSearch={value => {
                                    value !== '' && getClientDetails('receiver', value);
                                }}
                                // onChange={value => handleFilterChange('phoneNumber', value)}
                                onChange={value => setFormValue({ ...formValue, receiver: value })}
                                notFoundContent={null}
                                options={(dropdownData || []).map(data => ({
                                    value: data.value,
                                    label: data.label,
                                }))}
                                onSelect={handleSelectChange}
                                className="filter-select"
                                loading={loading}
                            />
                        </div>
                    </Form.Item>
                </div>
                <div className="col-span-1"></div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-4 gap-y-6 mt-6 item-center">
                    <Form.Item label="Transaction ID" name="transactionId">
                        <div className="">
                            <SearchOutlined className="custom-search-icon" />
                            <Select
                                showSearch={true}
                                // value={filters['phoneNumber']}
                                value={formValue.transactionId}
                                placeholder="Search Transaction ID"
                                defaultActiveFirstOption={false}
                                suffixIcon={null}
                                filterOption={false}
                                onSearch={value => {
                                    value !== '' && getClientDetails('transactionId', value);
                                }}
                                // onChange={value => handleFilterChange('phoneNumber', value)}
                                onChange={value =>
                                    setFormValue({ ...formValue, transactionId: value })
                                }
                                notFoundContent={null}
                                options={(dropdownData || []).map(data => ({
                                    value: data.value,
                                    label: data.label,
                                }))}
                                onSelect={handleSelectChange}
                                className="filter-select"
                                loading={loading}
                            />
                        </div>
                    </Form.Item> */}
            {/* <Form.Item label="Initiator" name="intiator">
                    <Input
                        placeholder="Search Intiator"
                        prefix={<SearchOutlined />}
                        className="w-full p-2"
                        onChange={e => setFormValue({ ...formValue, intiator: e.target.value })}
                    />
                </Form.Item> */}
            <Form.Item label="Currency" name="fiatCurrency">
                <MultiSelect
                    options={fiatOptions}
                    placeholder="Select an option"
                    name="fiatCurrency"
                    formValue={formValue}
                    onChange={selectedValues =>
                        setFormValue({
                            ...formValue,
                            fiatCurrency: selectedValues,
                        })
                    }
                />
            </Form.Item>
            {/* <Form.Item label="Crypto ID" name="cryptoCurrency">
                <MultiSelect
                    options={cryptoOptions}
                    placeholder="Select an option"
                    name="cryptoCurrency"
                    formValue={formValue}
                    onChange={selectedValues => {
                        setFormValue({
                            ...formValue,
                            cryptoCurrency: selectedValues,
                        });
                    }}
                />
            </Form.Item> */}

            {/* <Form.Item label={'Account Status'} name={'active'}>
                        <MultiSelect
                            options={[
                                { label: 'Active', value: 'true' },
                                { label: 'Inactive', value: 'false' },
                            ]}
                            placeholder="Select Account Status"
                            name="active"
                            formValue={formValue}
                            onChange={selectedValues => {
                                console.log('sele', selectedValues);
                                setFormValue({ ...formValue, active: selectedValues });
                            }}
                        />
                    </Form.Item> */}
            {/* <div className="col-span-1"></div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-4 gap-y-6 mt-6 item-center"> */}
            <Form.Item label="Status" name="status">
                <MultiSelect
                    options={transactionStatusGroup}
                    placeholder="Select an option"
                    name="statusGroup"
                    formValue={formValue}
                    onChange={selectedValues =>
                        setFormValue({ ...formValue, statusGroup: selectedValues })
                    }
                />
            </Form.Item>
            {/* <Form.Item label={'Amount Range'} name={'amountRange'} className="col-span-2">
                        <div className="flex gap-4">
                            <Input
                                name="amountRangeFrom"
                                placeholder="From"
                                value={formValue.amountRange?.from}
                                className="w-full p-2"
                                onChange={e =>
                                    setFormValue(prev => ({
                                        ...prev,
                                        amountRange: {
                                            ...prev.amountRange,
                                            from: e.target.value,
                                        },
                                    }))
                                }
                            />
                            <Input
                                name="amountRangeTo"
                                placeholder="To"
                                value={formValue.amountRange?.to}
                                className="w-full p-2"
                                onChange={e =>
                                    setFormValue(prev => ({
                                        ...prev,
                                        amountRange: {
                                            ...prev.amountRange,
                                            to: e.target.value,
                                        },
                                    }))
                                }
                            />
                        </div>
                    </Form.Item>
                    <div className="col-span-2"></div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-4 gap-y-6 mt-6 item-center">
                    <Form.Item label="Date Created" name="dateFilter">
                        <RangePicker
                            defaultValue={formValue.dateFilter}
                            format={dateFormat}
                            variant="outlined"
                            suffixIcon={
                                <img
                                    alt="calendar"
                                    src={`${image_base_url}/adminUi/calenderIcon.svg`}
                                    className="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px]"
                                />
                            }
                            className={`p-[6px] w-full h-full filter-range-picker-selected`}
                            popupClassName="calender-range-picker"
                            onChange={dates => {
                                setFormValue({
                                    ...formValue,
                                    dateFilter: dates,
                                });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Date Updated" name="dateUpdated">
                        <RangePicker
                            value={formValue.dateUpdated}
                            format={dateFormat}
                            variant="outlined"
                            suffixIcon={
                                <img
                                    alt="calendar"
                                    src={`${image_base_url}/adminUi/calenderIcon.svg`}
                                    className="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px]"
                                />
                            }
                            className={`p-[6px] w-full h-full filter-range-picker-selected`}
                            popupClassName="calender-range-picker"
                            onChange={dates => {
                                setFormValue({
                                    ...formValue,
                                    dateUpdated: dates,
                                });
                            }}
                        />
                    </Form.Item> */}
        </DrawerFilters>
    );
};

export default TransactionFilterDrawer;
