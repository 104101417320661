import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Dropdown, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationDrawer from './NotificationDrawer';
import { CheckCircleFilled } from '@ant-design/icons';
import { TOGGLE_SIDEBAR_COLLAPSE } from '../../redux/actions/actionTypes';
import { useWindowWidth } from '../../utils/common';
import { AdminLogoText } from '../../utils/Images';

const siderWidth = {
    width: 236,
    collapsedWidth: 82,
};

const Sidebar = ({ children }) => {
    const { Sider } = Layout;
    let pathname =
        window && window.location && window.location.pathname && window.location.hostname;
    pathname = pathname && pathname.split('.');
    const dispatch = useDispatch();
    const collapsed = useSelector(state => state.config.collapsed);
    const image_base_url = useSelector(state => state.config.image_base_url);
    const navigate = useNavigate();
    const location = useLocation();
    const windowWidth = useWindowWidth();

    const [notificationModal, setNotificationModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const initialDropdownValue = dropdownMenuItems[0];
        setSelectedItem(initialDropdownValue);
    }, []);

    const collapseSidebar = () => {
        dispatch({ type: TOGGLE_SIDEBAR_COLLAPSE });
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    const sidebarIcons = [
        {
            label: 'Dashboard',
            key: '/dashboard',
            icon: 'dashboardIcon.svg',
            onClick: () => handleItemClick('/dashboard'),
            className: '!m-0',
        },
        {
            label: 'Accounts',
            key: '/accounts',
            icon: 'multipleUsersIcon.svg',
            onClick: () => handleItemClick('/accounts'),
            className: '!m-0',
        },
        {
            label: 'Transactions',
            key: '/transactions',
            icon: 'exchangeIcon.svg',
            onClick: () => handleItemClick('/transactions'),
            className: '!m-0',
        },
        // {
        //     label: 'Reports',
        //     key: '/reports',
        //     icon: 'reportIcon.svg',
        //     onClick: () => handleItemClick('/reports'),
        //     className: '!m-0',
        // },
        // {
        //     label: 'Bank Withdrawals',
        //     key: '/withdrawals',
        //     icon: 'withdrawalIcon.svg',
        //     onClick: () => handleItemClick('/withdrawals'),
        //     className: '!m-0',
        // },
        {
            label: 'Profile',
            key: '/profile',
            icon: 'userIcon.svg',
            onClick: () => handleItemClick('/profile'),
            className: '!m-0',
        },
        {
            label: 'Settings',
            key: '/settings',
            icon: 'settingIcon.svg',
            onClick: () => handleItemClick('/settings'),
            className: '!m-0',
        },
        // {
        //     label: 'Notifications',
        //     key: '/notification',
        //     icon: 'bellIcon.svg',
        //     onClick: () => showDrawer(),
        //     className: '!m-0',
        // },
    ];

    const footerMobileIcons = [
        {
            label: 'Dashboard',
            key: '/dashboard',
            icon: 'dashboardIcon.svg',
            onClick: () => handleItemClick('/dashboard'),
        },
        {
            label: 'Accounts',
            key: '/accounts',
            icon: 'multipleUsersIcon.svg',
            onClick: () => handleItemClick('/accounts'),
        },
        {
            label: 'Transactions',
            key: '/transactions',
            icon: 'exchangeIcon.svg',
            onClick: () => handleItemClick('/transactions'),
        },
        // {
        //     label: 'Reports',
        //     key: '/reports',
        //     icon: 'reportIcon.svg',
        //     onClick: () => handleItemClick('/reports'),
        // },
        // {
        //     label: 'Bank Withdrawals',
        //     key: '/withdrawals',
        //     icon: 'withdrawalIcon.svg',
        //     onClick: () => handleItemClick('/withdrawals'),
        // },
        {
            label: 'Profile',
            key: '/profile',
            icon: 'userIcon.svg',
            onClick: () => handleItemClick('/profile'),
            className: '!m-0',
        },
        {
            label: 'Settings',
            key: '/settings',
            icon: 'settingIcon.svg',
            onClick: () => handleItemClick('/settings'),
        },
        {
            label: 'Logout',
            key: '/logout',
            icon: 'logout.svg',
            onClick: handleLogout,
        },
    ];

    const generateIconItem = (label, key, icon, onClick, className) => ({
        label,
        key,
        icon: (
            <img
                alt={icon}
                src={`${image_base_url}/adminUi/${icon}`}
                className="!min-w-[24px] !min-h-[24px]"
                data-e2e={label}
            />
        ),
        onClick,
        className,
    });

    const sidebarIconsList = sidebarIcons.map(icon =>
        generateIconItem(icon.label, icon.key, icon.icon, icon.onClick, icon.className),
    );

    const dropdownItems = [
        {
            label: 'junomoney',
            key: '0',
        },
        {
            label: 'junox',
            key: '1',
        },
    ];

    const generateDropdown = (label, key) => ({
        label: (
            <img
                alt={label}
                src={`${image_base_url}/adminUi/${label}.svg`}
                className="!min-w-[24px] !min-h-[24px]"
            />
        ),
        key,
    });

    const dropdownMenuItems = dropdownItems.map(item => generateDropdown(item.label, item.key));

    const toggleCollapse = () => {
        dispatch({ type: TOGGLE_SIDEBAR_COLLAPSE });
    };

    const handleSelect = e => {
        const selectedItem = dropdownMenuItems.find(item => item.key === e.key);
        setSelectedItem(selectedItem);
    };

    const handleItemClick = url => {
        navigate(url);
    };

    const showDrawer = () => {
        setNotificationModal(true);
    };
    const onClose = () => {
        setNotificationModal(false);
    };

    const Header = () => {
        const [activeTab, setActiveTab] = useState('junomoney');

        const buttons = [
            { id: 'junomoney', label: 'junomoney' },
            { id: 'juno-x', label: 'juno x' },
        ];
        return (
            <>
                <div className="flex justify-between items-center p-5 fixed top-0 w-full max-h-[50px] z-30 bg-white">
                    <h1 className="p-1.5 rounded-full bg-primary text-white text-center font-medium cursor-pointer">
                        CC
                    </h1>
                    {/* TODO: Uncomment when juno x and notification will come */}
                    {/* <Button
                        type="link"
                        icon={
                            <img
                                alt="menuIcon"
                                src={`${image_base_url}/adminUi/menuIcon.svg`}
                                className="!min-w-[18px] !min-h-[12px]"
                            />
                        }
                        onClick={toggleCollapse}
                        style={{
                            backgroundColor: 'white',
                        }}
                    />
                    <Button
                        type="link"
                        icon={
                            <img
                                alt="menuIcon"
                                src={`${image_base_url}/adminUi/bellIcon.svg`}
                                className="!min-w-[24px] !min-h-[24px]"
                            />
                        }
                        onClick={toggleCollapse}
                        style={{
                            backgroundColor: 'white',
                        }}
                    /> */}
                </div>
                {/* TODO: Will uncomment this when junox comes in admin */}
                {/* {!collapsed && (
                    <div className="z-50 mt-12 absolute border-t w-full p-6 bg-white">
                        <div className="flex space-x-2">
                            {buttons.map(button => (
                                <div
                                    key={button.id}
                                    onClick={() => setActiveTab(button.id)}
                                    className={`border-2 rounded-lg px-6 py-4 flex w-full flex-col items-center justify-center cursor-pointer relative ${
                                        activeTab === button.id
                                            ? 'border-gray-900'
                                            : 'border-gray-300'
                                    }`}>
                                    <span className="text-gray-500">Move to</span>
                                    <span className="text-xl font-semibold">{button.label}</span>
                                    {activeTab === button.id && (
                                        <span className="absolute top-2 right-2">
                                            <CheckCircleFilled />
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )} */}
            </>
        );
    };

    const Footer = () => {
        const pathName = window.location.pathname;
        return (
            <div className="flex items-center bg-white fixed bottom-0 w-full h-[70px] border-t p-2 z-50 cursor-pointer">
                {footerMobileIcons.map(item => (
                    <div
                        key={item.key}
                        className={`h-full mr-2 flex-1 flex items-center justify-center ${
                            pathName.includes(item.key)
                                ? 'border-b-2 border-primary bg-[#F4F4F5]  rounded-lg'
                                : ''
                        }`}
                        onClick={item.onClick}>
                        <img
                            src={`${image_base_url}/adminUi/${item.icon}`}
                            width="24px"
                            height="24px"
                            alt=""
                        />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
            hasSider>
            {/* Desktop sidebar */}
            {windowWidth !== 0 && windowWidth >= 1024 && (
                <>
                    <Sider
                        trigger={null}
                        className="!overflow-x-hidden hidden lg:block !overflow-auto !h-screen !fixed !left-0 !top-0 !bottom-0 !bg-white main-content hide-scrollbar"
                        collapsible
                        collapsed={collapsed}
                        collapsedWidth={`${siderWidth.collapsedWidth}px`}
                        width={`${siderWidth.width}px`}>
                        <div className="h-screen">
                            <div className="demo-logo-vertical" />
                            <div
                                className={
                                    'sidebar-menu ' +
                                    `${
                                        pathname && pathname[0] === 'admin'
                                            ? 'header-banner-bg-prod'
                                            : 'header-banner-bg-dev'
                                    }`
                                }>
                                <Button
                                    type="link"
                                    icon={
                                        <img
                                            alt="menuIcon"
                                            data-e2e="menu-icon"
                                            src={`${image_base_url}/adminUi/menuIcon.svg`}
                                            className="!min-w-[40px] !min-h-[40px]"
                                        />
                                    }
                                    onClick={toggleCollapse}
                                    // className="bg-white"
                                />
                                {/* {!collapsed && ( */}
                                <div className="sidebar-logo-block">
                                    <img
                                        alt={'logo'}
                                        src={`${image_base_url}${AdminLogoText}`}
                                        className={`sidebar-logo ${!collapsed ? 'active' : ''}`}
                                        data-e2e="juno-logo"
                                    />
                                </div>
                                {/* )} */}
                                {/* TODO: TO update this dropdown when juno x will come */}
                                {/* {!collapsed && (
                                    <Dropdown
                                        overlayClassName="custom-dropdown"
                                        menu={{
                                            items: dropdownMenuItems,
                                            selectable: true,
                                            defaultSelectedKeys: ['0'],
                                            onSelect: handleSelect,
                                        }}
                                        trigger={['click']}
                                        className="pt-[6px]">
                                        <a onClick={e => e.preventDefault()}>
                                            <Space className="!h-[22px] !w-[145px]">
                                                {selectedItem?.label}
                                                <div className="flex items-center">
                                                    <Button
                                                        type="link"
                                                        icon={
                                                            <img
                                                                alt="arrowDownIcon"
                                                                src={`${image_base_url}/adminUi/arrowDownIcon.svg`}
                                                                className="!w-full !min-w-[18px] !h-[18px]"
                                                            />
                                                        }
                                                        className="!w-[18px] !h-[18px] hover:!rounded-sm hover:!bg-[#E4E4E7]"
                                                    />
                                                </div>
                                            </Space>
                                        </a>
                                    </Dropdown>
                                )} */}
                            </div>
                            <div className="flex flex-col justify-between gap-[40px] h-[85vh] pt-5">
                                <Menu
                                    theme="light"
                                    mode="inline"
                                    selectedKeys={
                                        notificationModal ? '/notification' : location.pathname
                                    }
                                    items={sidebarIconsList}
                                    style={{ itemSelectedColor: '#F4F4F5' }}
                                    className={`custom-menu px-[16px] flex flex-col gap-[4px]`}
                                    collapsedWidth="!50px"
                                />
                                <div
                                    className="custom-menu flex px-[16px] gap-[4px] h-[50px] items-center cursor-pointer"
                                    onClick={handleLogout}>
                                    <div className="px-[13px] flex items-center gap-[12px] h-full hover:bg-[#F4F4F5] hover:rounded-md active:bg-[#D1D1D6] w-full">
                                        <img
                                            alt={'logout.svg'}
                                            data-e2e="logout"
                                            src={`${image_base_url}/adminUi/logout.svg`}
                                            className="!min-w-[24px] !min-h-[24px]"
                                        />
                                        {!collapsed && <div className="text-[16px]">Logout</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sider>
                    <Layout
                        className="hidden lg:block"
                        style={{
                            marginLeft: collapsed ? siderWidth.collapsedWidth : siderWidth.width,
                        }}>
                        {children}
                    </Layout>
                </>
            )}
            {windowWidth !== 0 && windowWidth < 1024 && (
                <>
                    {/* mobile layout */}
                    <Layout className="lg:hidden h-screen relative">
                        <Header />
                        <div className="pt-[50px] pb-[70px]">{children}</div>
                        <Footer />
                    </Layout>
                </>
            )}

            <NotificationDrawer onClose={onClose} open={notificationModal} />
        </Layout>
    );
};

export default Sidebar;
