import React, { useEffect, useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { Divider, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { ArrowUpIcon } from '../utils/Images';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import TransactionInfo from '../components/transactoinDetailTabs/TransactionInfo';
//import NotesTab from '../components/transactoinDetailTabs/NotesTab';
import Overview from '../components/transactoinDetailTabs/Overview';
import apiRequest from '../utils/api';
import { clientTransactionStatusMapping } from '../utils/constant';
import Loading from '../components/common/Loading';
import EventLog from '../components/transactoinDetailTabs/EventLog';
import BreadCrumHeader from '../components/common/BreadCrumHeader';
import { getClientTransactionStatusMapping } from '../utils/common';

const TransactionDetail = () => {
    const { transactionId } = useParams();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [loading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState(null);
    const navigate = useNavigate();

    const getTransactionDetails = async () => {
        const body = {
            adminRole: localStorage.getItem('adminRole'),
            email: localStorage.getItem('email'),
            paymentProviderId: localStorage.getItem('paymentProviderId'),
            transactionId: transactionId,
        };
        setLoading(true);
        try {
            const response = await apiRequest('/get-single-transaction-of-client', 'POST', body);

            if (response.success) {
                if (response.data) {
                    setTransaction(response.data[0]);
                }
            } else {
                console.error(response.error);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error posting data:', error);
        }
    };

    useEffect(() => {
        getTransactionDetails();
    }, [transactionId]);

    const items = [
        {
            key: '0',
            label: 'Details',
            children: (
                <Overview
                    clientData={
                        transaction && {
                            ...transaction.clientRef,
                            clientId: transaction.clientId,
                        }
                    }
                    transactionDetails={transaction && transaction.transactionDetails?.[0]}
                />
            ),
        },
        {
            key: '1',
            label: 'Transaction info',
            children: (
                <TransactionInfo
                    transaction={transaction && transaction}
                    onUpdate={getTransactionDetails}
                />
            ),
        },
        {
            key: '2',
            label: 'Events',
            children: <EventLog logs={transaction && transaction.status.history} />,
        },
        // {
        //     key: '4',
        //     label: 'Notes',
        //     children: <NotesTab />,
        // },
    ];

    const clientTransactionStatusMapping = getClientTransactionStatusMapping();
    const status = clientTransactionStatusMapping[transaction?.status?.code];

    const statusConfig = {
        completed: {
            statusText: 'Completed',
            statusClassName:
                'text-green-800 bg-[#ECFDF3] rounded-full py-1 px-5 text-sm font-medium',
        },
        rejected: {
            statusText: 'Rejected',
            statusClassName:
                'text-[#B54708] bg-[#FFFAEB;] rounded-full py-1 px-5 text-sm font-medium',
        },
        failed: {
            statusText: 'Failed',
            statusClassName:
                'text-[#B42318] bg-[#FEF3F2] rounded-full py-1 px-5 text-sm font-medium',
        },
        pending: {
            statusText: 'Pending',
            statusClassName:
                'text-sm px-5 py-1 bg-[#F4F4F5] text-center rounded-2xl text-[#3F3F46] font-medium capitalize',
        },
        default: {
            statusText: 'Processing',
            statusClassName:
                'text-sm px-5 py-1 bg-[#EFF8FF] text-center rounded-2xl text-[#175CD3] font-medium capitalize',
        },
    };

    const { statusText, statusClassName } = statusConfig[status] || statusConfig.default;

    return (
        <>
            <Sidebar>
                {loading || transaction === null ? (
                    <Loading />
                ) : (
                    <>
                        <div className="gap-[16px] m-6 lg:block hidden h-screen">
                            <BreadCrumHeader
                                subTab={{
                                    href: '/transactions',
                                    icon: 'exchangeIcon.svg',
                                    title: 'Transactions',
                                }}
                                currentTab={{ title: transaction.type }}
                            />
                            <div className="bg-white rounded-xl lg:block hidden">
                                <div className="grid grid-cols-3">
                                    <div className="border-r border-[#E4E4E7] overflow-hidden ">
                                        <div className="flex justify-between items-center p-6">
                                            <h1 className="text-lg font-semibold">Details</h1>
                                            {/* <div>
                                            <ActionButton
                                                icon={
                                                    <img
                                                        src={`${imageBaseUrl}${Download}`}
                                                        alt=""
                                                        className="w-4 h-4"
                                                    />
                                                }
                                            />
                                        </div> */}
                                        </div>
                                        <div className="flex items-center gap-4 px-6 h-fit">
                                            <img
                                                src={`${imageBaseUrl}${ArrowUpIcon}`}
                                                alt="arrow up"
                                            />
                                            <div className="flex-1">
                                                <div className="flex">
                                                    <p className="w-1/2 text-[#51525C] text-sm">
                                                        Status:
                                                    </p>
                                                    <p className={statusClassName}>{statusText}</p>
                                                </div>
                                                <p className="text-primary font-semibold text-base pt-4">
                                                    {transaction.type}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="">
                                            <Divider className="mt-6 mb-5" />
                                        </div>
                                        <Overview
                                            clientData={{
                                                ...transaction.clientRef,
                                                clientId: transaction.clientId,
                                            }}
                                            transactionDetails={
                                                transaction && transaction.transactionDetails?.[0]
                                            }
                                        />
                                    </div>
                                    <div className="col-span-2 p-6 account-details">
                                        <Tabs defaultActiveKey="1" items={items.slice(1)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:hidden">
                            <div className="flex justify-between items-center p-5 bg-[#F7F7F7]">
                                <h1
                                    className="font-semibold text-base cursor-pointer"
                                    onClick={() => navigate(-1)}>
                                    <LeftOutlined className="mr-2" />
                                    <span>Details</span>
                                </h1>
                            </div>
                            <div className="flex items-center gap-4 px-6 py-5 h-fit bg-white">
                                <img src={`${imageBaseUrl}${ArrowUpIcon}`} alt="arrow up" />
                                <div className="flex-1">
                                    <div className="flex">
                                        <p className="w-1/2 text-[#51525C] text-sm">Status:</p>
                                        <p className="w-1/2 text-sm px-3 py-1 bg-[#F4F4F5] text-center rounded-2xl text-[#3F3F46] font-medium">
                                            Pending Transfer
                                        </p>
                                    </div>
                                    <p className="text-primary font-semibold text-base pt-4">
                                        Withdrawal
                                    </p>
                                </div>
                            </div>
                            <div className="bg-[#F7F7F7]">
                                <Tabs
                                    defaultActiveKey="0"
                                    items={items}
                                    className="transaction-detail account-tabs-mob"
                                />
                            </div>
                        </div>
                    </>
                )}
            </Sidebar>
        </>
    );
};

export default TransactionDetail;
